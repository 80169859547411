import {FC, PropsWithChildren} from 'react'
import {HeaderProps} from 'react-table'
import { ArticleColumn } from '../core/_models'

type ArticleProps = {
    tableProps: PropsWithChildren<HeaderProps<ArticleColumn>>
}

const ArticleSelectionHeader: FC<ArticleProps> = ({tableProps}) => {
    return (
        <th {...tableProps.column.getHeaderProps()} className='w-10px pe-2'>
            <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                <input
                    className='form-check-input'
                    type='checkbox'
                    data-kt-check={false}
                    data-kt-check-target='#kt_table_Article .form-check-input'
                />
            </div>
        </th>
    )
}

export {ArticleSelectionHeader}