import {FC} from 'react'
import {Modal, Button} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import { ArticleUseQueryResponse } from '../../../../app/modules/apps/Article/core/QueryResponseProvider'

type AlertProps = {
  message: string
  show: boolean
  created?: boolean
  setShow: (show: boolean) => void
  setMessage: (message: string) => void
  setCreated?: (created: boolean) => void
}

const AlertModal: FC<AlertProps> = ({message, show, created, setShow, setMessage}) => {
  const {refetch} = ArticleUseQueryResponse()
  const handleClose = () => {
    setShow(false)
    setMessage('')
   
  }

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{useIntl().formatMessage({id: 'GENERAL.ALERT'})}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={handleClose}>
            {useIntl().formatMessage({id: 'GENERAL.SUBMIT'})}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export {AlertModal}
