/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {MenuComponent} from '../../../../../_metronic/assets/ts/components'
import {ID, KTSVG, QUERIES} from '../../../../../_metronic/helpers'
import {ConfirmModal} from '../../../../../_metronic/layout/components/others/ConfirmModal'
import {ArticleUseListView} from '../core/ListViewProvider'
import {deleteArticle} from '../core/_requests'
import {useIntl} from 'react-intl'
import {ArticleUseQueryResponse} from '../core/QueryResponseProvider'
import { AlertModal } from '../../../../../_metronic/layout/components/others/AlertModal'

type Props = {
  id: ID
}

const ArticleActionsCell: FC<Props> = ({id}) => {
  const [show, setShow] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const {setMessageA, setShowA, messageA, showA} = ArticleUseListView()
  const {query} = ArticleUseQueryResponse()
  const queryClient = useQueryClient()


  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])


  const deleteArticleItem = useMutation(() => deleteArticle(id), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.ARTICLES_LIST}-${query}`])
    },
    onError: (error:any) => {
      setShowA(true)
      setMessageA(error.response.data.message)
      },
    
  })

  const openConfirmModal = () => {
    setShow(true)
  }

  const confirmDelete = async () => {
    if (confirm) {
        await deleteArticleItem.mutateAsync();
      
    }
  };
  

  useEffect(() => {
    confirmDelete()
    return () => {
      setShow(false)
      setConfirm(false)
    }
  }, [confirm])

  const intl = useIntl()

  return (
    <>
      <ConfirmModal show={show} setShow={setShow} setConfirm={setConfirm} />
      <AlertModal message={messageA} setMessage={setMessageA} show={showA} setShow={setShowA} />
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm me-2'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        {intl.formatMessage({id: 'MENU.ACTIONS'})}
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </a>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        {/* <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={openEditModal}>
            {intl.formatMessage({id: 'ACTIONS.EDIT'})}
          </a>
        </div> */}
        <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            data-kt-relay-table-filter='delete_row'
            onClick={openConfirmModal}
          >
            {intl.formatMessage({id: 'ACTIONS.DELETE'})}
          </a>
        </div>
      </div>
    </>
  )
}

export {ArticleActionsCell}
