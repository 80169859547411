import { Column } from 'react-table'
import { useIntl } from 'react-intl'
import { Truck } from '../core/_models'
import { TruckSelectionHeader } from './TruckSelectionHeader'
import { TruckSelectionCell } from './TruckSelectionCell'
import { TruckCustomHeader } from './TruckCustomHeader'
import { TruckActionsCell } from './TruckActionsCell'

const trucksColumns: ReadonlyArray<Column<Truck>> = [
    {
        Header: (props) => <TruckSelectionHeader tableProps={props} />,
        id: 'selection',
        Cell: ({ ...props }) => <TruckSelectionCell id={props.data[props.row.index].id} />,
    },
    {
        Header: (props) => (
            <TruckCustomHeader tableProps={props} title={useIntl().formatMessage({ id: "TRUCK.TYPE" })} className='min-w-125px' />
        ),
        accessor: 'type_truck',
        id: 'type_truck',
    },
    {
        Header: (props) => (
            <TruckCustomHeader tableProps={props} title={useIntl().formatMessage({ id: "TRUCK.CODE" })} className='min-w-125px' />
        ),
        accessor: 'truck_code',
        id: 'truck_code',
    },
    {
        Header: (props) => (
            <TruckCustomHeader tableProps={props} title={useIntl().formatMessage({ id: "TRUCK.NUMBER" })} className='min-w-125px' />
        ),
        accessor: 'truck_number',
        id: 'truck_number',
    },
    {
        Header: (props) => (
            <TruckCustomHeader tableProps={props} title={useIntl().formatMessage({ id: "COLUMN.ACTIONS" })} className='text-end min-w-100px' />
        ),
        id: 'actions',
        Cell: ({ ...props }) => <TruckActionsCell id={props.data[props.row.index].id} />,
    },
]

export { trucksColumns }