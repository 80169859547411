import clsx from 'clsx'
import {Row} from 'react-table'
import {FC} from 'react'
import { ArticleColumn } from '../core/_models'

type ArticleProps = {
    row: Row<ArticleColumn>
}

const ArticleCustomRow: FC<ArticleProps> = ({row}) => (
    <tr {...row.getRowProps()}>
    {row.cells.map((cell) => {
    return (
        <td
            {...cell.getCellProps()}
            className={clsx({
            'text-end min-w-100px': cell.column.id === 'actions' || cell.column.id === 'status',
            })}
            key={cell.column.id}
        >
            {cell.render('Cell')}
        </td>
        )
    })}
    </tr>
)

export {ArticleCustomRow}