/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

type Props = {
  role: string
}

export function AsideMenuMain(props: Props) {
  const intl = useIntl()

  return (
    <>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            {intl.formatMessage({id: 'MENU.MANAGEMENT'})}
          </span>
        </div>
      </div>
      {(props.role === 'admin' || props.role === 'provider') && (
        <AsideMenuItem
          to='/app/article/management'
          title={intl.formatMessage({id: 'MENU.ARTICLE'})}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/ecommerce/ecm004.svg'
        />
      )}
      {(props.role === 'admin' || props.role === 'agent') && (
        <AsideMenuItemWithSub
          to='/crafted/pages'
          title={intl.formatMessage({id: 'MENU.TRUCKS_MANAGEMENT'})}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/ecommerce/ecm006.svg'
        >
          <AsideMenuItem
            to='/Truck/List'
            title={intl.formatMessage({id: 'MENU.LIST'})}
            hasBullet={true}
          />
          <AsideMenuItem
            to='/Truck/Add'
            title={intl.formatMessage({id: 'MENU.ADD'})}
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      )}



      {(props.role === 'admin' || props.role === 'agent') && (
        <AsideMenuItemWithSub
          to='/crafted/pages'
          title={intl.formatMessage({id: 'MENU.FILE_MANAGEMENT'})}
          fontIcon='bi-archive'
          icon='/media/icons/duotune/maps/map001.svg'
        >
          <AsideMenuItem
            to='/app/file/list'
            title={intl.formatMessage({id: 'MENU.FILE_LIST'})}
            hasBullet={true}
          />
          <AsideMenuItem
            to='/app/file/add'
            title={intl.formatMessage({id: 'MENU.ADD_FILE'})}
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      )}
      {props.role === 'admin' && (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                {intl.formatMessage({id: 'MENU.GENERAL_ADMIN'})}
              </span>
            </div>
          </div>

          <AsideMenuItem
            to='/apps/user-management/users'
            icon='/media/icons/duotune/general/gen051.svg'
            title={intl.formatMessage({id: 'MENU.USERS_MANAGEMENT'})}
            fontIcon='bi-layers'
          />
        </>
      )}
    </>
  )
}
