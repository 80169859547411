import {Response} from '../../../../../_metronic/helpers'
import * as Yup from 'yup'


export interface Article {
  id?: number
  name: string
  created_at?: string
  updated_at?: string
}

export const initialArticle: Article = {
  id: undefined,
  name: '',
 created_at: '',
 updated_at: ''
}

export const ArticleSchema = Yup.object({
  name: Yup.string().min(2, 'VALIDATION.NAME').required('VALIDATION.NAME.REQUIRED'),
})

export type ArticleColumn = {
  id?: number
  name?: string
  created_at?: string
  updated_at?: string
}

export type ArticleQueryResponse = Response<Array<Article>>
