/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {useMutation, useQueryClient} from 'react-query'
import {ColumnInstance, Row, useTable} from 'react-table'
import {KTCard, KTCardBody, KTSVG, QUERIES} from '../../../_metronic/helpers'
import {ListLoading} from '../../../_metronic/helpers/components/loading/ListLoading'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {TruckCustomHeaderColumn} from './columns/CustomHeaderColumn'
import {TruckCustomRow} from './columns/CustomRow'
import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider, useQueryRequest} from './core/QueryRequestProvider'
import {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
} from './core/QueryResponseProvider'
import {Truck} from './core/_models'
import {TruckAddPage} from './TruckAddWrapper'
import {deleteSelectedTrucks, getTrucks} from './core/_requests'
import {trucksColumns} from './columns/_columns'
import {useNavigate} from 'react-router-dom'
import {AlertModal} from '../../../_metronic/layout/components/others/AlertModal'
import {ConfirmModal} from '../../../_metronic/layout/components/others/ConfirmModal'
import { ListPagination } from '../../../_metronic/helpers/components/pagination/ListPagination'


type PaginatedItemsState = {
  itemOffset: number;
};

type ChildComponentProps = {
  sendSearch: (data: string) => void;
};
const TrucksListGrouping = () => {
  const [show, setShow] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const {selected, clearSelected} = useListView()
  const queryClient = useQueryClient()
  const {query} = useQueryResponse()

  const deleteSelectedItems = useMutation(() => deleteSelectedTrucks(selected), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.TRUCKS_LIST}-${query}`])
      clearSelected()
    },
    onError: () => {},
  })

  const openConfirmModal = () => {
    setShow(true)
  }

  const confirmDelete = async () => {
    if (confirm) {
      await deleteSelectedItems.mutateAsync()
    }
  }

  useEffect(() => {
    confirmDelete()
    return () => {
      setShow(false)
      setConfirm(false)
    }
  }, [confirm])

  const intl = useIntl()

  return (
    <div className='d-flex justify-content-end align-items-center'>
      <ConfirmModal show={show} setShow={setShow} setConfirm={setConfirm} />
      <div className='fw-bolder me-5'>
        <span className='me-2'>{selected.length}</span>{' '}
        {intl.formatMessage({id: 'BUTTON.SELECTED'})}
      </div>

      <button type='button' className='btn btn-danger' onClick={openConfirmModal}>
        {intl.formatMessage({id: 'BUTTON.DELETE_SELECTED'})}
      </button>
    </div>
  )
}

const TrucksListToolbar = () => {
  const navigate = useNavigate()

  const intl = useIntl()
  return (
    <div className='d-flex justify-content-end' data-kt-truck-table-toolbar='base'>
      {/* <UsersListFilter /> */}
      {/* begin::Add user */}
      <button
        type='button'
        className='btn btn-primary'
        onClick={() => {
          navigate('/Truck/Add')
        }}
      >
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        {intl.formatMessage({id: 'BUTTON.ADD_TRUCK'})}
      </button>
      {/* end::Add user */}
    </div>
  )
}

const TrucksListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <div className='card-title'>{useIntl().formatMessage({id: 'TITLE.TRUCKS'})}</div>
      <div className='card-toolbar'>
        {selected.length > 0 ? <TrucksListGrouping /> : <TrucksListToolbar />}
      </div>
    </div>
  )
}


const TrucksTable : FC<ChildComponentProps> = ({sendSearch}) => {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const trucks = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => trucks, [trucks])
  const [truckList, setTrucks] = useState<Truck[]>([])
  const columns = useMemo(() => trucksColumns, [])

 


  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data
  })



  const intl = useIntl()

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  const handleSendData = () => {
    sendSearch(searchTerm)
  };

  return (
    <KTCardBody className='py-4'>
      {/* begin::Search */}
      <div className='d-flex align-items-center position-relative my-1'>
        <KTSVG
          path='/media/icons/duotune/general/gen021.svg'
          className='svg-icon-1 position-absolute ms-6'
        />
        <input
          type='text'
          className='form-control form-control-solid w-250px ps-14'
          onChange={handleSearchChange}
          value={searchTerm}
        />
        <button type="button" className ="btn btn-primary mx-4" onClick={handleSendData}>Rechercher</button>
      </div>
      {/* end::Search */}
      <div className='table-responsive'>
        <table
          id='kt_table_trucks'
          className='table table-striped align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<Truck>) => (
                <TruckCustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className=' fs-5 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows
                .map((row: Row<Truck>, i) => {
                  prepareRow(row)
                  return <TruckCustomRow row={row} key={`row-${i}-${row.id}`} />
                })
            ) : (
              <tr>
                <td colSpan={20}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    {intl.formatMessage({id: 'TABLE.NO_ITEMS_FOUND'})}
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className='mt-4'>
      <ListPagination
        useQueryResponseLoading={useQueryResponseLoading}
        useQueryResponsePagination={useQueryResponsePagination}
        useQueryRequest={useQueryRequest}
        item='parcels'
      />
      </div>
      {isLoading && <ListLoading />}
    </KTCardBody>
  )
}

const TruckModalFormHeader = () => {
  const {setItemIdForUpdate} = useListView()
  const intl = useIntl()
  return (
    <div className='modal-header'>
      <h2 className='fw-bolder'>{intl.formatMessage({id: 'BUTTON.ADD_TRUCK'})}</h2>
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-pricings-modal-action='close'
        onClick={() => setItemIdForUpdate(undefined)}
        style={{cursor: 'pointer'}}
      >
        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
      </div>
    </div>
  )
}

const TruckEditModal: FC = () => {
  return (
    <div>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_truck'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered modal-xl'>
          <div className='modal-content'>
            <TruckModalFormHeader />
            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              <TruckAddPage />
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </div>
  )
}

const TrucksList: FC<ChildComponentProps> = ({sendSearch}) => {


 
  const {itemIdForUpdate, messageA, setMessageA, setShowA, showA} = useListView()
 
  

  return (
    <>
      <KTCard>
        <TrucksListHeader />
        <TrucksTable sendSearch={sendSearch}/>
      </KTCard>
      {itemIdForUpdate !== undefined && <TruckEditModal />}
      <AlertModal message={messageA} setMessage={setMessageA} show={showA} setShow={setShowA} />
    </>
  )
}

const TruckListPage: FC<ChildComponentProps> = ({sendSearch}) => {
  const intl = useIntl()

  const listTruckBreadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({id: 'MENU.TRUCKS_MANAGEMENT'}),
      path: '/truck/list',
      isActive: true,
      isSeparator: false,
    },
    {
      title: '',
      path: '',
      isActive: true,
      isSeparator: false,
    },
  ]
  return (
    <>
      <PageTitle breadcrumbs={listTruckBreadCrumbs}>
        {intl.formatMessage({id: 'TITLE.TRUCKS_LIST'})}
      </PageTitle>
      <TrucksList sendSearch={sendSearch}/>
    </>
  )
}

const TruckListWrapper: FC = () => {
  const [Search, setSearch] = useState("")
  const processSearch = (data: string) => {
    setSearch(data)
  }
  return (
    <>
      <QueryRequestProvider>
        <QueryResponseProvider Search={Search}>
          <ListViewProvider>
            <TruckListPage  sendSearch={processSearch}/>
          </ListViewProvider>
        </QueryResponseProvider>
      </QueryRequestProvider>
    </>
  )
}

export {TruckListWrapper}
