import {useIntl} from 'react-intl'
import { ArticleListToolbar } from '../components/ArticleListToolbar'
import { ArticleListGrouping } from '../components/ArticleListGrouping'
import { ArticleUseListView } from '../core/ListViewProvider'

const ArticleListHeader = () => {
    const {selected} = ArticleUseListView()
    return (
        <div className='card-header border-0 pt-6'>
        <div className='card-title'>
            <h3 className='card-label'>{useIntl().formatMessage({id: 'MENU.ARTICLE_LIST'})}</h3>
        </div>
        <div className='card-toolbar'>
            {selected.length > 0 ? <ArticleListGrouping /> : <ArticleListToolbar />}
        </div>
        </div>
    )
    }

export {ArticleListHeader}