import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {ReclamationWrapper} from '../pages/reclamation/ReclamationWrapper'

import {TruckListWrapper} from '../pages/Truck/TruckListWrapper'
import {TruckAddWrapper} from '../pages/Truck/TruckAddWrapper'

import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import {ParamPageWrapper} from '../pages/Configuation/ParamPageWrapper'
import {RequireRole} from './RequireRole'

import ArticlePage from '../modules/apps/Article/ArticlePage'

type privateRoutesProps = {
  model: string
}

const PrivateRoutes = (props: privateRoutesProps) => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const FilePage = lazy(() => import('../modules/apps/file/FilePage'))


  return (
    <Routes>
      <Route element={<MasterLayout role={props.model} />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={props.model==="agent"?<Navigate to='/app/file/list' />:<Navigate to='/app/article/management' />} />
        {/* Pages */}
        <Route element={<RequireRole allowedRoles={['admin']} role={props.model} />}>
          <Route path='reclamation' element={<ReclamationWrapper />} />
        </Route>

        <Route element={<RequireRole allowedRoles={['admin']} role={props.model} />}>
          <Route path='configuration' element={<ParamPageWrapper />} />
        </Route>
        <Route element={<RequireRole allowedRoles={['admin']} role={props.model} />}>
          <Route path='menu-test' element={<MenuTestPage />} />
        </Route>

        {/* Camion */}
        <Route element={<RequireRole allowedRoles={['admin', 'agent']} role={props.model} />}>
          <Route path='Truck/List' element={<TruckListWrapper />} />
        </Route>
        <Route element={<RequireRole allowedRoles={['admin', 'agent']} role={props.model} />}>
          <Route path='/Truck/Add' element={<TruckAddWrapper />} />
        </Route>

        {/* Lazy Modules */}
        <Route element={<RequireRole allowedRoles={['admin', 'agent']} role={props.model} />}>
          <Route
            path='app/file/*'
            element={
              <Suspense fallback={<TopBarProgress />}>
                <FilePage />
              </Suspense>
            }
          />
        </Route>


        <Route element={<RequireRole allowedRoles={['admin', 'provider']} role={props.model} />}>
          <Route
            path='app/article/*'
            element={
              <SuspensedView>
                <ArticlePage />
              </SuspensedView>
            }
          />
        </Route>
        <Route
          element={<RequireRole allowedRoles={['admin', 'agent', 'provider']} role={props.model} />}
        >
          <Route
            path='crafted/pages/profile/*'
            element={
              <SuspensedView>
                <ProfilePage />
              </SuspensedView>
            }
          />
        </Route>
        <Route element={<RequireRole allowedRoles={['admin']} role={props.model} />}>
          <Route
            path='crafted/pages/wizards/*'
            element={
              <SuspensedView>
                <WizardsPage />
              </SuspensedView>
            }
          />
        </Route>
        <Route element={<RequireRole allowedRoles={['admin']} role={props.model} />}>
          <Route
            path='crafted/widgets/*'
            element={
              <SuspensedView>
                <WidgetsPage />
              </SuspensedView>
            }
          />
        </Route>
        <Route
          element={<RequireRole allowedRoles={['admin', 'agent', 'provider']} role={props.model} />}
        >
          <Route
            path='crafted/account/*'
            element={
              <SuspensedView>
                <AccountPage />
              </SuspensedView>
            }
          />
        </Route>
        <Route
          element={<RequireRole allowedRoles={['admin', 'agent', 'provider']} role={props.model} />}
        >
          <Route
            path='apps/chat/*'
            element={
              <SuspensedView>
                <ChatPage />
              </SuspensedView>
            }
          />
        </Route>
        <Route element={<RequireRole allowedRoles={['admin']} role={props.model} />}>
          <Route
            path='apps/user-management/*'
            element={
              <SuspensedView>
                <UsersPage />
              </SuspensedView>
            }
          />
        </Route>
        {/* Error Pages */}
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
