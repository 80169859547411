import {FC, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {ColumnInstance, Row, useTable} from 'react-table'
import {ChildComponentProps, KTCardBody, KTSVG} from '../../../../../_metronic/helpers'
import {ListLoading} from '../../../../../_metronic/helpers/components/loading/ListLoading'
import {ListPagination} from '../../../../../_metronic/helpers/components/pagination/ListPagination'
import {ArticleCustomHeaderColumn} from '../columns/CustomHeaderColumn'
import {ArticleCustomRow} from '../columns/CustomRow'
import {ArticleColumns} from '../columns/_columns'
import {ArticleUseQueryRequest} from '../core/QueryRequestProvider'
import {
  ArticleUseQueryResponseData,
  ArticleUseQueryResponseLoading,
  ArticleUseQueryResponsePagination,
} from '../core/QueryResponseProvider'
import { ArticleColumn} from '../core/_models'


export const ArticleTable : FC<ChildComponentProps> = ({sendSearch}) => {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const intl = useIntl()
  const Articles = ArticleUseQueryResponseData()
  const isLoading = ArticleUseQueryResponseLoading()
  const columns = useMemo(() => ArticleColumns, [])
  const data = useMemo(() => Articles, [Articles])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  const handleSendData = () => {
    sendSearch!(searchTerm)
  };

  return (
    <KTCardBody className='py-4'>
      {/* begin::Search */}
      <div className='d-flex align-items-center position-relative my-1'>
        <KTSVG
          path='/media/icons/duotune/general/gen021.svg'
          className='svg-icon-1 position-absolute ms-6'
        />
        <input
          type='text'
          className='form-control form-control-solid w-250px ps-14'
          onChange={handleSearchChange}
          value={searchTerm}
        />
        <button type="button" className ="btn btn-primary mx-4" onClick={handleSendData}>Rechercher</button>
      </div>
      {/* end::Search */}
      <div className='table-responsive'>
        <table
          id='kt_table_relays'
          className='table table-striped align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<ArticleColumn>) => (
                <ArticleCustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className=' fs-5 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows
                .map((row: Row<ArticleColumn>, index: number) => {
                  prepareRow(row)
                  return <ArticleCustomRow key={index} row={row} />
                })
            )  : (
              <tr>
                <td colSpan={headers.length} className='text-center'>
                  {intl.formatMessage({id: 'TABLE.NO_DATA'})}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {isLoading && <ListLoading />}
      <ListPagination
        useQueryRequest={ArticleUseQueryRequest}
        useQueryResponseLoading={ArticleUseQueryResponseLoading}
        useQueryResponsePagination={ArticleUseQueryResponsePagination}
        item='addresses'
      />
    </KTCardBody>
  )
}
