import {useIntl} from 'react-intl'
import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageTitle} from '../../../../_metronic/layout/core'
import { ArticleWrapper } from './list/ArticleWrapper'

const ArticlePage = () => {
  const intl = useIntl()
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='management'
          element={
            <>
              <PageTitle
                breadcrumbs={[
                  {
                    title: intl.formatMessage({id: 'MENU.ARTICLE'}),
                    path: '/app/article/management',
                    isSeparator: false,
                    isActive: false,
                  },
                ]}
              >
                {intl.formatMessage({id: 'MENU.ARTICLE_LIST'})}
              </PageTitle>
              <ArticleWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/app/Article/management' />} />
    </Routes>
  )
}

export default ArticlePage
