/* eslint-disable react-hooks/exhaustive-deps */
import {useFormik} from 'formik'
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useQuery} from 'react-query'
import {GetTranslate, isNotEmpty, KTCard, KTSVG, QUERIES} from '../../../../../_metronic/helpers'
import {ArticleQueryResponseProvider} from '../core/QueryResponseProvider'
import {ArticleListViewProvider, ArticleUseListView} from '../core/ListViewProvider'
import {ArticleUseQueryResponse} from '../core/QueryResponseProvider'
import {Article, ArticleSchema, initialArticle} from '../core/_models'
import {createArticle, getArticleById, updateArticle} from '../core/_requests'
import {AxiosError} from 'axios'
import {ArticleTable} from './ArticleTable'
import {AlertModal} from '../../../../../_metronic/layout/components/others/AlertModal'
import {ArticleQueryRequestProvider} from '../core/QueryRequestProvider'
import {ArticleListHeader} from './ArticleListHeader'

type FormProps = {
  isItemLoading: boolean

}

type ChildComponentProps = {
  sendSearch: (data: string) => void;
};

const ArticleForm: FC<FormProps> = ({isItemLoading}) => {
  const {setItemIdForUpdate, setMessageA, setShowA, showA, messageA, created, setshowAModal} = ArticleUseListView()
  const {refetch} = ArticleUseQueryResponse()
  const intl = useIntl()
  const [name, setName] = useState<string>('')

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
      
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: {name:''},
    validationSchema: ArticleSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {     
          await createArticle({name: name}).then(() => {    
          
          
          setMessageA(intl.formatMessage({id: 'ARTICLE.CREATE_SUCCESS'}))  
          setShowA(true); setshowAModal!(false); cancel(true)} );      
      } catch (error: any) {       
        setMessageA(error.response.data.message)
        setShowA(true)
      } finally {      
        setSubmitting(true)          
      }

    },
  })
  useEffect(() => {
    formik.setValues({ name }); 
  }, [name]); 

  return (
    <div>
     <AlertModal message={messageA} setMessage={setMessageA} show={showA} setShow={setShowA} created={created}/>
      <form
        id='kt_modal_add_address_form'
        className='form'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div className=' modal-body scroll-y m-4 ' id='Article'>

            <div className='text-center'>
              <div className='fv-row mb-0'>
                <label className='d-inline overflow-hidden col-lg-3 col-form-label required fw-bold fs-6'>
                  {intl.formatMessage({id: 'ARTICLE_ADD.NAME'})}
                </label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-white'
                  {...formik.getFieldProps('title')}
                  value={name} 
                  onChange={(e) => setName(e.target.value)}
                  
                />
                <div className='text-danger mt-2'>
                  {formik?.touched?.name && formik.errors.name && GetTranslate(formik.errors.name)}
                </div>
              </div>
            </div>

        </div>
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => setshowAModal!(false)}
            className='btn btn-light me-3'
            data-kt-addresses-modal-action='cancel'
            disabled={formik.isSubmitting || isItemLoading}
          >
            {intl.formatMessage({id: 'GENERAL.CANCEL'})}
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-addresses-modal-action='submit'
            disabled={formik.isSubmitting || isItemLoading || !formik.isValid}
          >
            <span className='indicator-label'>{intl.formatMessage({id: 'GENERAL.SUBMIT'})}</span>
            {(formik.isSubmitting || isItemLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}


const ArticleFormHeader: FC = () => {
  
  const intl = useIntl()
  return (
    <div className='modal-header'>
      <h2 className='fw-bolder'>{intl.formatMessage({id: 'MENU.ARTICLE'})}</h2>
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-addresses-modal-action='close'
        
        style={{cursor: 'pointer'}}
      >
        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
      </div>
    </div>
  )
}

const ArticleModal: FC = () => {
  return (
    <div>
      <div
        className='modal fade show d-block'
        id='kt_modal_show_movement_details'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered modal-xl'>
          <div className='modal-content'>
            <ArticleFormHeader />
            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              <ArticleForm isItemLoading={false}  />
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </div>
  )
}

const ArticleList: FC<ChildComponentProps> = ({sendSearch}) => {
  const {setShowA, showAModal} = ArticleUseListView()

  return (
    <div>
      <KTCard>
        <ArticleListHeader />
        <ArticleTable sendSearch={sendSearch}/>
      </KTCard>
      {showAModal === true && <ArticleModal />}
      {/* <AlertModal message={messageA} show={showA} setShow={setShowA} setMessage={setMessageA} /> */}
    </div>
  )
}

const ArticleWrapper : FC = () => {
  const [Search, setSearch] = useState("")
  const processSearch = (data: string) => {
    setSearch(data)
  }
  return (
  <ArticleQueryRequestProvider>
    <ArticleQueryResponseProvider Search={Search}>
      <ArticleListViewProvider>
        <ArticleList sendSearch={processSearch} />
      </ArticleListViewProvider>
    </ArticleQueryResponseProvider>
  </ArticleQueryRequestProvider>
)
  }

export {ArticleWrapper}
