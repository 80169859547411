import {Column} from 'react-table'
import {useIntl} from 'react-intl'
import { ArticleActionsCell } from './ArticleActionsCell'
import { ArticleCustomHeader } from './ArticleCustomHeader'
import { ArticleSelectionCell} from './ArticleSelectionCell'
import { ArticleSelectionHeader } from './ArticleSelectionHeader'
import { ArticleColumn } from '../core/_models'

const ArticleColumns: ReadonlyArray<Column<ArticleColumn>> = [
    {
        Header: (props) => <ArticleSelectionHeader tableProps={props} />,
        id: 'selection',
        Cell: ({...props}) => <ArticleSelectionCell id={props.data[props.row.index].id} />,
      },
    {
        Header: (props) => (
            <ArticleCustomHeader
                tableProps={props}
                title={useIntl().formatMessage({id: 'COLUMN.NAME'})}
                className='min-w-125px'
            />
        ),
        accessor: 'name',
        id: 'name',
    },
    {
      Header: (props) => (
          <ArticleCustomHeader
              tableProps={props}
              title={useIntl().formatMessage({id: 'COLUMN.NAME'})}
              className='min-w-125px'
          />
      ),
      accessor: 'created_at',
      id: 'created_at',
  },
  {
    Header: (props) => (
        <ArticleCustomHeader
            tableProps={props}
            title={useIntl().formatMessage({id: 'COLUMN.NAME'})}
            className='min-w-125px'
        />
    ),
    accessor: 'updated_at',
    id: 'updated_at',
},
    {
        Header: (props) => (
          <ArticleCustomHeader
            tableProps={props}
            title={useIntl().formatMessage({id: 'COLUMN.ACTIONS'})}
            className='text-end min-w-100px'
          />
        ),
        id: 'actions',
        Cell: ({...props}) => <ArticleActionsCell id={props.data[props.row.index].id} />,
      },
]

export {ArticleColumns}