import axios, { AxiosResponse } from "axios"
import { Article, ArticleQueryResponse } from "./_models"
import { ID } from "../../../../../_metronic/helpers"

const API_URL = process.env.REACT_APP_API_URL
const Article_API_URL = `${API_URL}/product`

const getArticle = (): Promise<Article[]> => {
return axios
    .get(Article_API_URL)
    .then((response: AxiosResponse<Article[]>) => response.data)
}

const getArticlePaginated = (query: String, search?: string): Promise<ArticleQueryResponse> => {
return axios
    .get(`${Article_API_URL}/page?${query}`, { params: {search: search} })
    .then((response: AxiosResponse<ArticleQueryResponse>) => response.data)
}
  
const getArticleById = (id: ID): Promise<Article | undefined> => {
return axios
    .get(`${Article_API_URL}/${id}`)
    .then((response: AxiosResponse<Article>) => response.data)
}

const createArticle = (Article: Article): Promise<Article> => {
return axios
    .post(Article_API_URL, Article)
    .then((response: AxiosResponse<Article>) => response.data)
}

const updateArticle = (Article: Article): Promise<Article> => {
return axios
    .post(`${Article_API_URL}/${Article.id}/update`, Article)
    .then((response: AxiosResponse<Article>) => response.data)
}

const deleteArticle = (id: ID): Promise<any> => {
return axios
    .post(`${Article_API_URL}/${id}/delete`)
    .then((response: AxiosResponse<any>) => response.data)
}

const deleteSelectedAddresses = (ids: ID[]): Promise<any> => {
const requests = ids.map((id) => deleteArticle(id))
return axios.all(requests)
}

export {
    getArticle,
    getArticlePaginated,
    getArticleById,
    createArticle,
    updateArticle,
    deleteArticle,
    deleteSelectedAddresses,
}