/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState, useEffect, useMemo} from 'react'
import {useQuery} from 'react-query'
import {
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  PaginationState,
  QUERIES,
  stringifyRequestQuery,
  WithChildren,
} from '../../../../../_metronic/helpers'
import {getArticlePaginated} from './_requests'
import { Article, ArticleColumn } from './_models'
import {ArticleUseQueryRequest} from './QueryRequestProvider'

const ArticleQueryResponseContext = createResponseContext<Article>(initialQueryResponse)

const ArticleQueryResponseProvider: FC<WithChildren> = ({children, Search}) => {
  const {state} = ArticleUseQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)     
    }
    else{
      setQuery(' ')
    }
  }, [updatedQuery, Search])


  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.ARTICLES_LIST}-${query}`,
    () => {
      return getArticlePaginated(query, Search)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  return (
    <ArticleQueryResponseContext.Provider
      value={{isLoading: isFetching, refetch, response, query}}
    >
      {children}
    </ArticleQueryResponseContext.Provider>
  )
}

const ArticleUseQueryResponse = () => useContext(ArticleQueryResponseContext)

const ArticleUseQueryResponseData = () => {
  const {response} = ArticleUseQueryResponse()
  if (!response) {
    return []
  }
  const data = [] as Array<ArticleColumn>
  response.data?.forEach((item: Article) => {
    data.push({
      id: item.id,
      name: item.name,
      created_at: item.created_at?.substring(0,item?.created_at.lastIndexOf("T")),
      updated_at: item.updated_at?.substring(0,item?.updated_at.lastIndexOf("T"))
    } as ArticleColumn)
  })
  return data || []
}

const ArticleUseQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }
  const {response} = ArticleUseQueryResponse()
  if (!response) {
    return defaultPaginationState
  }
  const {current_page, links, per_page} = response
  return {current_page, links, per_page} as PaginationState
}

const ArticleUseQueryResponseLoading = () => {
  const {isLoading} = ArticleUseQueryResponse()
  return isLoading
}

export {
  ArticleQueryResponseProvider,
  ArticleUseQueryResponse,
  ArticleUseQueryResponseData,
  ArticleUseQueryResponsePagination,
  ArticleUseQueryResponseLoading,
}
