/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {MenuComponent} from '../../../../_metronic/assets/ts/components'
import {ID, KTSVG, QUERIES} from '../../../../_metronic/helpers'
import {deleteTruck} from '../core/_requests'
import {useListView} from '../core/ListViewProvider'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {useIntl} from 'react-intl'
import {ConfirmModal} from '../../../../_metronic/layout/components/others/ConfirmModal'
import { AlertModal } from '../../../../_metronic/layout/components/others/AlertModal'

type Props = {
  id: ID
}

const TruckActionsCell: FC<Props> = ({id}) => {
  const [show, setShow] = useState(false)
  const {setShowA, setMessageA, messageA, showA} = useListView()
  const [confirm, setConfirm] = useState(false)
  const {setItemIdForUpdate} = useListView()
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }

  const deleteTruckItem = useMutation(() => deleteTruck(id), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.TRUCKS_LIST}-${query}`])
    },
    onError: (err: any) => {
      setShowA(true)
      setMessageA(err.response.data.message)
    },
  })

  const openConfirmModal = () => {
    setShow(true)
  }

  const confirmDelete = async () => {
    if (confirm) {
      await deleteTruckItem.mutateAsync()
    }
  }

  useEffect(() => {
    confirmDelete()
    return () => {
      setShow(false)
      setConfirm(false)
    }
  }, [confirm])

  const intl = useIntl()

  return (
    <>
      <ConfirmModal show={show} setShow={setShow} setConfirm={setConfirm} />
      
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm me-2'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        {intl.formatMessage({id: 'MENU.ACTIONS'})}
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </a>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={openEditModal}>
            {intl.formatMessage({id: 'ACTIONS.EDIT'})}
          </a>
        </div>
        <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            data-kt-trucks-table-filter='delete_row'
            onClick={openConfirmModal}
          >
            {intl.formatMessage({id: 'ACTIONS.DELETE'})}
          </a>
        </div>
      </div>
    </>
  )
}

export {TruckActionsCell}
