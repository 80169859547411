import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../../_metronic/helpers'
import { ArticleUseListView } from '../core/ListViewProvider'

const ArticleListToolbar = () => {
    const intl = useIntl()
    const {setshowAModal} = ArticleUseListView()
    const openAddArticleModal = () => {
        setshowAModal!(true)
      }
    return (
        <div className='d-flex justify-content-end' data-kt-relay-table-toolbar='base'>
        {/* <UsersListFilter /> */}
      <button type='button' className='btn btn-primary' onClick={openAddArticleModal}>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            {intl.formatMessage({id: 'BUTTON.ADD_ARTICLE'})}
        </button>
        </div>
    )
}

export {ArticleListToolbar}