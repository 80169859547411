const QUERIES = {
  USERS_LIST: 'users-list',
  PARCELS_LIST: 'parcels-list',
  PRICINGS_LIST: 'pricings-list',
  TRUCKS_LIST: 'trucks-list',
  RELAY_POINTS_LIST: 'relays-list',
  MOVEMENTS_LIST: 'movements-list',
  RECEPTIONS_LIST: 'receptions-list',
  ADDRESS_BOOK_LIST: 'addresses-list',
  ARTICLES_LIST: 'articles-list',
  FILES_LIST: 'files-list'
}

export {QUERIES}
