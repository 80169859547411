/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useMutation, useQueryClient} from 'react-query'
import {QUERIES} from '../../../../../_metronic/helpers'
import {ConfirmModal} from '../../../../../_metronic/layout/components/others/ConfirmModal'
import { ArticleUseListView } from '../core/ListViewProvider'
import { ArticleUseQueryResponse } from '../core/QueryResponseProvider'
import { deleteSelectedAddresses } from '../core/_requests'

const ArticleListGrouping = () => {
    const [show, setShow] = useState(false)
    const [confirm, setConfirm] = useState(false)
    const {selected, clearSelected, setMessageA, setShowA} = ArticleUseListView()
    const queryClient = useQueryClient()
    const {query} = ArticleUseQueryResponse()
    
    const deleteSelectedItems = useMutation(() => deleteSelectedAddresses(selected), {
        onSuccess: () => {
        queryClient.invalidateQueries([`${QUERIES.ADDRESS_BOOK_LIST}-${query}`])
        setMessageA(intl.formatMessage({id: 'ADDRESS_BOOK.DELETE_SUCCESS'}))
        setShowA(true)
        clearSelected()
        },
        onError: (error:any) => {
        setMessageA(error.response.data.message)
        setShowA(true)
        },
    })
    
    const openConfirmModal = () => {
        setShow(true)
    }
    
    const confirmDelete = async () => {
        if (confirm) {
        await deleteSelectedItems.mutateAsync()
        }
    }
    
    useEffect(() => {
        confirmDelete()
        return () => {
        setShow(false)
        setConfirm(false)
        }
    }, [confirm])
    
    const intl = useIntl()
    
    return (
        <div className='d-flex justify-content-end align-items-center'>
        <ConfirmModal show={show} setShow={setShow} setConfirm={setConfirm} />
        <div className='fw-bolder me-5'>
            <span className='me-2'>{selected.length}</span>{' '}
            {intl.formatMessage({id: 'BUTTON.SELECTED'})}
        </div>
    
        <button type='button' className='btn btn-danger' onClick={openConfirmModal}>
            {intl.formatMessage({id: 'BUTTON.DELETE_SELECTED'})}
        </button>
        </div>
    )
    }

export {ArticleListGrouping}